.login-form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  height: 100%;
}



@media (min-width: 600px) {
  .login-form {
    width: 200%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
    height: 100%;
  } 
}
/*
@media all and (min-width: 600px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 60%;
    }

  }

ul {
  display: table;
  margin: 0 auto;
  text-align: left;
}
*/